import { gql } from "graphql-tag";
import { CouponPayload } from "src/api/Coupon";
import {
  activeClasses,
  activeClassesVariables,
  bulkClass,
  bulkClassVariables,
  classItem,
  classItemVariables,
  classStats,
  classStatsVariables,
  createClass,
  createClassVariables,
  deleteClass,
  deleteClassVariables,
  deleteClasses,
  deleteClassesVariables,
  editBulkClass,
  editBulkClassVariables,
  exportClasses,
  exportClassesVariables,
  filteredClasses,
  filteredClassesCounts,
  filteredClassesCountsVariables,
  filteredClassesVariables,
  updateClass,
  updateClassVariables,
} from "types/code-generator";
import { TypedDocumentNode } from "types/graphql";
import { ClassTimePayload } from "./ClassTime";
import { DepartmentPayload } from "./Department";
import { StudentInfoRequestPayload } from "./StudentInfoRequest";

export const ClassPayload = gql`
  fragment ClassPayload on Class {
    id
    additionalFeeAmount
    additionalFeeBillingCategory {
      id
      title
    }
    additionalFeeTitle
    addons {
      id
      title
      description
      billingCategory {
        id
        title
      }
      price
    }
    allowAdditionalStudents
    allowNonMembers
    applyAnnualFee
    attendedOnCheckIn
    attendedAfterCheckIn
    autoEnroll
    agreements {
      id
      title
      description
      required
    }
    capacity
    chargeAmounts(where: { status: { not: Processed } }) {
      id
      atRegistration
      date
      membershipOffering {
        id
        title
      }
      price
    }
    chargeOnCheckIn
    charges {
      id
      amount
      appliesTo
      billingCategory {
        id
        title
      }
      description
    }
    chargeType
    checkIn
    classTimes {
      ...ClassTimePayload
    }
    collectTaxes
    coupons {
      ...CouponPayload
    }
    createdAt
    credits {
      id
      amount
      billingCategory {
        id
        title
      }
      description
    }
    customerCheckIn
    customerInfoRequests
    department {
      id
      active
      annualFeeAmount
      annualFeeAtBeginningOfYear
      annualFeeBillingCategory {
        id
        title
      }
      annualFeeCanBeAppliedAtOfferingLevel
      annualFeeMax
      annualFeeType
      contactName
      default
      email
      phone
      title
    }
    description
    discounts {
      id
      amount
      amountType
      max
      min
      discountType
    }
    displayOpenSpots
    earlyCheckIn
    earlyCheckInType
    endDate
    entryLevel
    evaluationReminderFrequency
    excludedDates
    fees {
      id
      title
      amount
      amountType
    }
    freePresentation
    frequency
    hideDefaultStaff
    instructors {
      id
      email
      firstName
      lastName
      phone
    }
    location {
      id
      title
      address1
      city
      state
      zip
    }
    maxCheckIns
    maxEnrollment
    meetParticipation
    membershipOfferings {
      id
    }
    membershipPrices {
      id
      price
      membershipOfferingId
    }
    membershipCheckIns {
      id
      amount
      type
      membershipOfferingId
    }
    membershipCheckInPrices {
      id
      price
      membershipOfferingId
    }
    minAge
    minAgeType
    maintainWaitlist
    maxAge
    maxAgeType
    multiClassDiscount
    multiStudentDiscount
    noClassTimes
    organization {
      id
    }
    participantType
    paymentFrequency
    paymentReminderDays
    paymentRate
    price
    priceAdjustments {
      id
      date
      price
    }
    private
    program {
      id
      billingCategory {
        id
      }
      department {
        id
        title
      }
      title
      description
    }
    prorateInProgressClasses
    prorateStartEnd
    prorateExcludedDates
    publishDate
    publishOnWebsite
    registrationOpen
    registrationClose
    registrationAdjustments {
      id
      membershipOfferingId
      registrationOpen
      registrationClose
    }
    registrations {
      id
      accountOwner {
        id
        email
        firstName
        lastName
        phone
        userAgreements {
          id
          createdAt
          agreement {
            id
          }
        }
      }
      class {
        id
        classTimes {
          id
          day
          startTime
        }
        program {
          id
        }
      }
      createdAt
      createdBy {
        id
        firstName
        lastName
      }
      dateApproved
      events {
        id
        by {
          id
          firstName
          lastName
        }
        createdAt
        type
        value
      }
      finalDate
      roster {
        id
        accountOwner {
          id
          phone
          email
          firstName
          lastName
          settings {
            id
            type
            value
          }
          stripeToken
        }
        birthDate
        firstName
        lastName
        gender
        healthConcerns
        healthQuestionResponses {
          id
          response
          healthQuestion {
            id
            slug
          }
        }
        note
        phone
      }
      status
      student {
        id
        accountOwner {
          id
          phone
          email
          firstName
          lastName
          settings {
            id
            type
            value
          }
          stripeToken
        }
        birthDate
        firstName
        lastName
        gender
        healthConcerns
        healthQuestionResponses {
          id
          response
          healthQuestion {
            id
            slug
          }
        }
        note
        phone
      }
      studentInfoRequestResponses {
        id
        description
        additionalResponse
        studentInfoRequest {
          id
        }
      }
      updatedAt
    }
    requiresUSASwimming
    requiresRegistration
    startDate
    status
    studentInfoRequests {
      ...StudentInfoRequestPayload
    }
    tags {
      id
      title
    }
    templates {
      id
    }
    title
    type
    unpublishDate
    updatedAt
    waitlistCapacity
    zone {
      id
      title
    }
  }
  ${ClassTimePayload}
  ${CouponPayload}
  ${StudentInfoRequestPayload}
`;

export const ListClassPayload = gql`
  fragment ListClassPayload on Class {
    id
    agreements {
      id
      title
    }
    allowAdditionalStudents
    autoEnroll
    classTimes {
      ...ClassTimePayload
    }
    department {
      ...DepartmentPayload
    }
    description
    displayOpenSpots
    endDate
    entryLevel
    excludedDates
    hideDefaultStaff
    instructors {
      id
      firstName
      lastName
    }
    location {
      id
      title
      address1
    }
    maintainWaitlist
    maxAge
    maxEnrollment
    minAge
    noClassTimes
    organization {
      id
      timezone
    }
    paymentFrequency
    price
    private
    program {
      id
      title
      description
    }
    publishDate
    publishOnWebsite
    registrationOpen
    registrationClose
    registrations {
      id
      accountOwner {
        id
        phone
        email
        firstName
        lastName
        userAgreements {
          id
          createdAt
          agreement {
            id
          }
        }
      }
      createdAt
      dateApproved
      finalDate
      roster {
        id
        firstName
        lastName
      }
      status
      student {
        id
        accountOwner {
          id
          phone
          email
          firstName
          lastName
        }
        birthDate
        firstName
        healthQuestionResponses {
          id
          response
          healthQuestion {
            id
            slug
          }
        }
        lastName
        note
        gender
      }
      studentInfoRequestResponses {
        id
        description
        studentInfoRequest {
          id
          slug
        }
      }
      updatedAt
    }
    startDate
    status
    studentInfoRequests {
      id
      description
    }
    tags {
      id
      title
    }
    title
    type
    unpublishDate
    waitlistCapacity
    zone {
      id
      title
    }
  }
  ${ClassTimePayload}
  ${DepartmentPayload}
`;

export const GroupOfferingPayload = gql`
  fragment GroupOfferingPayload on Class {
    id
    additionalFeeAmount
    additionalFeeBillingCategory {
      id
      title
    }
    additionalFeeTitle
    addons {
      id
      title
      description
      price
    }
    allowAdditionalStudents
    allowCustomerCancellation
    allowCustomerMove
    allowNonMembers
    applyAnnualFee
    attendedOnCheckIn
    attendedAfterCheckIn
    autoEnroll
    agreements {
      id
      availableForAllOfferings
      title
    }
    capacity
    chargeAmounts {
      id
      atRegistration
      date
      membershipOfferingId
      price
      status
    }
    chargeOnCheckIn
    charges {
      id
      amount
      appliesTo
      billingCategory {
        id
        title
      }
      description
    }
    chargeType
    checkIn
    classTimes {
      id
      allDayPresentation
      day
      date
      endTime
      location {
        id
        title
      }
      startTime
      zone {
        id
        title
      }
    }
    collectTaxes
    communications {
      id
    }
    coupons {
      id
    }
    createdAt
    credits {
      id
      amount
      billingCategory {
        id
        title
      }
    }
    customerCheckIn
    customerInfoRequests
    department {
      id
      title
    }
    description
    discounts {
      id
      amount
      amountType
      max
      min
      discountType
    }
    displayDaysTimes
    displayMembershipPricing
    displayOpenSpots
    earlyCheckIn
    earlyCheckInType
    earlyCheckInClose
    earlyCheckInCloseType
    endDate
    entryLevel
    evaluationReminderFrequency
    excludedDates
    fees {
      id
      amount
      amountType
      title
    }
    freePresentation
    frequency
    hideDefaultStaff
    instructors {
      id
      firstName
      lastName
    }
    integration
    location {
      id
      title
    }
    maxCheckIns
    maxEnrollment
    meetParticipation
    membershipOfferings {
      id
      program {
        id
        title
      }
      status
      title
      type
    }
    membershipPrices {
      id
      price
      membershipOfferingId
      membershipOffering {
        id
        program {
          id
          title
        }
      }
    }
    membershipCheckIns {
      id
      amount
      membershipOfferingId
      membershipOffering {
        id
        program {
          id
          title
        }
      }
      type
    }
    membershipCheckInsClose {
      id
      amount
      membershipOfferingId
      type
    }
    membershipCheckInPrices {
      id
      price
      membershipOfferingId
      membershipOffering {
        id
        program {
          id
          title
        }
      }
    }
    minAge
    minAgeType
    maintainWaitlist
    maxAge
    maxAgeType
    multiClassDiscount
    multiStudentDiscount
    noClassTimes
    organization {
      id
    }
    participantType
    paymentFrequency
    paymentReminderDays
    paymentRate
    price
    pricePerCheckIn
    priceAdjustments {
      id
      date
      membershipOfferingId
      price
    }
    private
    program {
      id
      description
      title
    }
    prorateInProgressClasses
    prorateStartEnd
    prorateExcludedDates
    publishDate
    publishOnWebsite
    registrationAdjustments {
      id
      membershipOfferingId
      registrationOpen
      registrationClose
    }
    registrationOpen
    registrationClose
    requiresRegistration
    requiresUSASwimming
    sendPaymentReminders
    startDate
    status
    studentInfoRequests {
      id
    }
    tags {
      id
      title
    }
    templates {
      id
    }
    title
    type
    unpublishDate
    updatedAt
    viewOthers
    waitlistCapacity
    zone {
      id
      title
    }
  }
`;

export const UpdateClassMutation = gql`
  mutation updateClass($data: ClassCreateInput!, $where: UniqueInput!) {
    updateClass(data: $data, where: $where) {
      ...ClassPayload
    }
  }
  ${ClassPayload}
` as TypedDocumentNode<updateClass, updateClassVariables>;

export const DeleteClassMutation = gql`
  mutation deleteClass($id: String!) {
    deleteClass(where: { id: $id }) {
      id
    }
  }
` as TypedDocumentNode<deleteClass, deleteClassVariables>;

export const CreateClassMutation = gql`
  mutation createClass($data: ClassCreateInput!) {
    createClass(data: $data) {
      ...ClassPayload
    }
  }
  ${ClassPayload}
` as TypedDocumentNode<createClass, createClassVariables>;

export const ClassStatsQuery = gql`
  query classStats($where: ClassStatsInput!) {
    classStats(where: $where) {
      accountOwners
      billable
      coupons
      credits
      discounts
      due
      globalFees
      globalDiscounts
      globalCoupons
      globalAddons
      globalStudentInfoRequests
      globalAgreements
      paid
      refunds
    }
  }
` as TypedDocumentNode<classStats, classStatsVariables>;

export const CurrentClassesQuery = gql`
  query currentClasses($organizationId: String!, $skip: Int) {
    classes(
      where: { organizationId: $organizationId, status: { notIn: [Archived] } }
      skip: $skip
    ) {
      ...ListClassPayload
    }
  }
  ${ListClassPayload}
`;

export const FilteredClassesQuery = gql`
  query filteredClasses(
    $where: ClassWhereInput!
    $filter: String
    $skip: Int
    $take: Int
    $orderBy: ClassOrderByInput
  ) {
    classes(
      where: $where
      filter: $filter
      take: $take
      skip: $skip
      orderBy: $orderBy
    ) {
      id
      addons {
        id
      }
      agreements {
        id
      }
      allowAdditionalStudents
      allowNonMembers
      autoEnroll
      capacity
      chargeAmounts(where: { status: { not: Processed } }) {
        id
        price
        date
        atRegistration
      }
      charges {
        id
        amount
      }
      checkIn
      classTimes {
        id
        allDayPresentation
        date
        day
        endTime
        location {
          id
          title
        }
        startTime
        zone {
          id
          title
        }
      }
      coupons {
        id
      }
      createdAt
      credits {
        id
        amount
      }
      customerCheckIn
      department {
        id
        title
      }
      description
      discounts {
        id
      }
      displayDaysTimes
      displayOpenSpots
      earlyCheckIn
      earlyCheckInType
      earlyCheckInClose
      earlyCheckInCloseType
      endDate
      excludedDates
      fees {
        id
        title
        amount
        amountType
      }
      freePresentation
      frequency
      hideDefaultStaff
      instructors {
        id
        firstName
        lastName
      }
      integration
      location {
        id
        title
        address1
      }
      maintainWaitlist
      maxAge
      maxAgeType
      maxCheckIns
      maxEnrollment
      membershipCheckIns {
        id
        amount
        type
        membershipOfferingId
        membershipOffering {
          id
          program {
            title
          }
        }
      }
      membershipOfferings {
        id
        memberships(where: { status: { in: [Active, Pending] } }) {
          id
          accountOwnerId
        }
        program {
          title
        }
        title
      }
      membershipPrices {
        id
        price
        membershipOfferingId
        membershipOffering {
          id
          program {
            title
          }
        }
      }
      minAge
      minAgeType
      multiClassDiscount
      multiStudentDiscount
      organization {
        id
        timezone
      }
      participantType
      paymentFrequency
      paymentRate
      price
      pricePerCheckIn
      private
      program {
        id
        title
      }
      prorateExcludedDates
      prorateInProgressClasses
      prorateStartEnd
      publishDate
      publishOnWebsite
      registrations(where: { status: { not: Error } }) {
        id
        accountOwner {
          id
          email
          firstName
          lastName
          phone
        }
        finalDate
        roster {
          id
          firstName
          lastName
        }
        status
        student {
          id
          firstName
          lastName
        }
      }
      registrationOpen
      registrationClose
      requiresRegistration
      startDate
      status
      studentInfoRequests {
        id
      }
      tags {
        id
        order
        title
      }
      title
      type
      unpublishDate
      waitlistCapacity
    }
  }
` as TypedDocumentNode<filteredClasses, filteredClassesVariables>;

export const FilteredClassesCountsQuery = gql`
  query filteredClassesCounts($where: ClassWhereInput!, $filter: String) {
    classesCounts(where: $where, filter: $filter) {
      approvedRegistrations
      availableSoon
      canceledRegistrations
      completedRegistrations
      declinedRegistrations
      movedRegistrations
      pendingRegistrations
      registrationNotRequired
      registrationRequired
      totalAvailability
      totalAccountOwners
      totalClasses
      totalRegistrations
      unlimitedEnrollments
      waitlistRegistrations
    }
  }
` as TypedDocumentNode<filteredClassesCounts, filteredClassesCountsVariables>;

export const DeleteClassesMutation = gql`
  mutation deleteClasses($where: ClassWhereInput!, $filter: String) {
    deleteClasses(where: $where, filter: $filter) {
      count
    }
  }
` as TypedDocumentNode<deleteClasses, deleteClassesVariables>;

export const BulkClassQuery = gql`
  query bulkClass(
    $where: BulkClassWhereInput
    $filter: String
    $checkField: String
    $checkValue: String
  ) {
    bulkClass(
      where: $where
      filter: $filter
      checkField: $checkField
      checkValue: $checkValue
    ) {
      addons
      agreements
      allowCustomerCancellation
      allowCustomerMove
      allowNonMembers
      attendedOnCheckIn
      autoEnroll
      chargeAmounts
      checkIn
      communications
      coupons
      discounts
      endDate
      excludedDates
      fees
      hideDefaultStaff
      infoRequests
      instructors
      maintainWaitlist
      maxEnrollment
      membershipOfferings
      membershipPrices
      prorateExcludedDates
      prorateInProgressClasses
      prorateStartEnd
      publishDate
      registrationAdjustments
      registrationClose
      registrationOpen
      requiresRegistration
      startDate
      tags
      total
      totalPerDepartment
      unpublishDate
    }
  }
` as TypedDocumentNode<bulkClass, bulkClassVariables>;

export const ExportClassesMutation = gql`
  mutation exportClasses(
    $where: ClassWhereInput!
    $filter: String
    $forDuplication: Boolean
  ) {
    exportClasses(
      where: $where
      filter: $filter
      forDuplication: $forDuplication
    )
  }
` as TypedDocumentNode<exportClasses, exportClassesVariables>;

export const EditBulkClassMutation = gql`
  mutation editBulkClass(
    $where: BulkClassWhereInput!
    $data: BulkClassCreateInput!
    $filter: String
  ) {
    editBulkClass(where: $where, data: $data, filter: $filter) {
      count
    }
  }
` as TypedDocumentNode<editBulkClass, editBulkClassVariables>;

export const ActiveClassesQuery = gql`
  query activeClasses(
    $where: ClassWhereInput!
    $filter: String
    $skip: Int
    $take: Int
    $orderBy: ClassOrderByInput
  ) {
    classes(
      where: $where
      filter: $filter
      take: $take
      skip: $skip
      orderBy: $orderBy
    ) {
      ...ClassPayload
    }
  }
  ${ClassPayload}
` as TypedDocumentNode<activeClasses, activeClassesVariables>;

export const ClassQuery = gql`
  query classItem($id: String!) {
    class(where: { id: $id }) {
      ...ClassPayload
    }
  }
  ${ClassPayload}
` as TypedDocumentNode<classItem, classItemVariables>;
