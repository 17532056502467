import Cookies from "js-cookie";

export function getToken() {
  let token = Cookies.get("accessToken");

  if (
    !token &&
    window.sessionStorage.getItem("kiosk") &&
    window.sessionStorage.getItem("kiosk") !== "true"
  ) {
    token = window.sessionStorage.getItem("kiosk");
  }

  return token;
}

export function removeToken() {
  let domain = "captyn.com";

  if (process.env.NEXT_PUBLIC_STAGE === "demo") {
    domain = "nytpac.com";
  }

  const cookieConfig = {
    domain,
  };

  if (
    process.env.NODE_ENV === "development" ||
    process.env.NEXT_PUBLIC_STAGE === "test"
  ) {
    domain = "local.localhost";
    cookieConfig.domain = domain;
    Cookies.remove("accessToken");
    Cookies.remove("accessToken", {
      domain: "localhost",
    });
  }

  Cookies.remove("accessToken");
  Cookies.remove("accessToken", cookieConfig);
  // localStorage.removeItem("captynToken");
}

export function setToken(token) {
  const expires = new Date(new Date().getTime() + 365 * 24 * 60 * 60 * 1000);

  let domain = "captyn.com";

  if (
    process.env.NODE_ENV === "development" ||
    process.env.NEXT_PUBLIC_STAGE === "test"
  ) {
    domain = "local.localhost";
    Cookies.set("accessToken", token);
  }

  if (process.env.NEXT_PUBLIC_ENVIRONMENT === "ecs") {
    domain = "captyn.cloud";
  }

  if (process.env.NEXT_PUBLIC_STAGE === "demo") {
    domain = "nytpac.com";
  }

  const cookieConfig = {
    expires,
    domain,
  };

  // Cookies.set("accessToken", token);
  Cookies.set("accessToken", token, cookieConfig);
  // localStorage.setItem("captynToken", token);
}
