import AddonModel from "src/models/AddonModel";
import AgreementModel from "src/models/AgreementModel";
import CommunicationModel from "src/models/CommunicationModel";
import CouponModel from "src/models/CouponModel";
import ProgramModel from "src/models/ProgramModel";
import { MembershipOfferingTypes } from "src/utils/constants";
import {
  CapacityStatus,
  DepartmentPayload,
  Fee,
  Frequency,
  IntegrationType,
  MembershipStatus,
  OfferingStatus,
  RenewalType,
  studentInfoRequests,
  tags,
} from "types/code-generator";
import BillingCategoryModel from "./BillingCategoryModel";
import { ChargeModel } from "./ChargeModel";
import { CreditModel } from "./CreditModel";
import MembershipModel from "./MembershipModel";
import OrganizationModel from "./OrganizationModel";
import UserModel from "./UserModel";

export const isMembershipOffering = (item): item is MembershipOfferingModel =>
  item instanceof MembershipOfferingModel;

export interface PriceLevel {
  id?: string;
  price: number;
  number: number;
}

class MembershipOfferingModel {
  addons: AddonModel[] = [];
  agreements: AgreementModel[] = [];
  allowCustomerCancellation: boolean = false;
  allowSharing: boolean = true;
  autoEnroll: boolean = true;
  billingCategory: BillingCategoryModel;
  capacity: CapacityStatus;
  charges: ChargeModel[] = [];
  checkInsPerWeek: number;
  checkInsPerMonth: number;
  communications: CommunicationModel[] = [];
  coupons: CouponModel[] = [];
  createdAt: Date;
  credits: CreditModel[] = [];
  department: DepartmentPayload;
  description: string;
  displayOpenSpots: boolean = true;
  endDate: Date;
  endDays: number;
  fees: Fee[] = [];
  freePresentation: string;
  id: string;
  integration: IntegrationType;
  maintainWaitlist: boolean = true;
  maxCheckIns: number;
  maxEnrollment: number;
  maxMembers: number;
  memberships: MembershipModel[] = [];
  organization: OrganizationModel;
  participantType: string = "Member";
  passType: string;
  paymentFrequency: Frequency = Frequency.Monthly;
  price: number;
  priceLevels: PriceLevel[] = [];
  pricePerCheckIn: number;
  program: ProgramModel;
  prorate: boolean = true;
  prorateStartEnd: boolean = true;
  publishDate: Date;
  recurringDate: Date;
  registrationClose: Date = null;
  registrationOpen: Date = null;
  renewalType: RenewalType = RenewalType.None;
  startDate: Date;
  status: OfferingStatus = OfferingStatus.InProgress;
  studentInfoRequests: studentInfoRequests["studentInfoRequests"] = [];
  tags: tags["tags"] = [];
  title: string = null;
  type: (typeof MembershipOfferingTypes)[keyof typeof MembershipOfferingTypes] =
    MembershipOfferingTypes.Membership;
  unpublishDate: Date;
  updatedAt: Date;
  waitlistCapacity: number;

  constructor(data) {
    Object.assign(this, data);
  }

  getRoster() {
    const registrations = this.memberships || [];
    const members = registrations.reduce((acc, r) => {
      if (r.status === MembershipStatus.Active) {
        for (const s of r.roster) {
          acc[s.id] = s;
        }
      }
      return acc;
    }, {});

    return Object.values(members).map((u) => new UserModel(u));
  }

  getPending() {
    const registrations = this.memberships || [];
    const members = registrations
      .filter((r) => r.accountOwner)
      .reduce((acc, r) => {
        if (r.status === MembershipStatus.Pending) {
          acc[r.accountOwner.id] = r.accountOwner;
        }
        return acc;
      }, {});

    return Object.values(members).map((u) => new UserModel(u));
  }

  getActive() {
    const registrations = this.memberships || [];
    const members = registrations.reduce((acc, r) => {
      if (r.status === MembershipStatus.Active) {
        acc[r.accountOwner.id] = r.accountOwner;
      }
      return acc;
    }, {});

    return Object.values(members).map((u) => new UserModel(u));
  }

  getInactive() {
    const registrations = this.memberships || [];
    const members = registrations.reduce((acc, r) => {
      if (r.status === MembershipStatus.Closed && r.accountOwner) {
        acc[r.accountOwner.id] = r.accountOwner;
      }
      return acc;
    }, {});

    return Object.values(members).map((u) => new UserModel(u));
  }

  getWaitlist() {
    const registrations = this.memberships || [];
    const members = registrations.reduce((acc, r) => {
      if (r.status === MembershipStatus.Waitlist && r.accountOwner) {
        acc[r.accountOwner.id] = r.accountOwner;
      }
      return acc;
    }, {});

    return Object.values(members).map((u) => new UserModel(u));
  }
}

export default MembershipOfferingModel;
