import React from "react";
import { Route, Switch } from "react-router-dom";
import Loading from "src/components/layout/Loading";
import Main from "src/components/layout/Main";
import { useStore } from "src/stores/Store";
import lazyImport from "src/utils/lazyImport";

const Admin = lazyImport(() => import("src/routes/admin/Admin"));
const SuperAdmin = lazyImport(() => import("src/routes/superAdmin/SuperAdmin"));
const Home = lazyImport(() => import("src/routes/Home"));
const Welcome = lazyImport(() => import("src/routes/Welcome"));
const FindDetail = lazyImport(() => import("src/routes/FindDetail"));
const Login = lazyImport(() => import("src/routes/Login"));
const Password = lazyImport(() => import("src/routes/Password"));
const Email = lazyImport(() => import("src/routes/cart/Email"));
const RegisterPassword = lazyImport(() => import("src/routes/cart/Password"));
const NewAccount = lazyImport(() => import("src/routes/cart/NewAccount"));
const AccountDetails = lazyImport(
  () => import("src/routes/cart/AccountDetails")
);
const RegisterNotifications = lazyImport(
  () => import("src/routes/RegisterNotifications")
);
const ResetPassword = lazyImport(() => import("src/routes/ResetPassword"));
const TermsOfService = lazyImport(() => import("src/routes/TermsOfService"));
const PrivacyPolicy = lazyImport(() => import("src/routes/PrivacyPolicy"));
const CompleteRegistration = lazyImport(
  () => import("src/routes/checkout/CompleteRegistration")
);
const RegistrationSuccess = lazyImport(
  () => import("src/routes/checkout/RegistrationSuccess")
);
const InformationRequest = lazyImport(
  () => import("src/routes/checkout/InformationRequest")
);
const CallbackStripe = lazyImport(() => import("src/routes/CallbackStripe"));
const Account = lazyImport(() => import("src/routes/account/Account"));
const NoMatch = lazyImport(() => import("src/routes/NoMatch"));
const Store = lazyImport(() => import("src/routes/Store"));
const Explore = lazyImport(() => import("src/routes/Explore"));
const Cart = lazyImport(() => import("src/routes/cart/Cart"));
const Search = lazyImport(() => import("src/routes/Search"));
const StartRegistration = lazyImport(
  () => import("src/routes/StartRegistration")
);
const MeetSignup = lazyImport(() => import("src/routes/MeetSignup"));
const ParticipantDetails = lazyImport(
  () => import("src/routes/cart/ParticipantDetails")
);
const Code = lazyImport(() => import("src/routes/Code"));
const Logout = lazyImport(() => import("src/routes/Logout"));

interface Props {}

// const ssr = ["/login"];

export default function Body(props: Props) {
  const store = useStore();

  return (
    <Main
      isSuperAdmin={store.user?.isSalesOrAbove}
      isViewingAs={Boolean(store.superAdmin)}
    >
      <React.Suspense fallback={<Loading delay={250} />}>
        <Switch>
          <Route exact path="/" component={Home} />
          <Route path="/welcome" component={Welcome} />
          <Route exact path="/find" component={StartRegistration} />
          <Route exact path="/find/:id" component={FindDetail} />
          <Route path="/login" component={Login} />
          <Route path="/logout" component={Logout} />
          <Route path="/code/:code" component={Code} />
          <Route path="/password" component={Password} />
          <Route path="/reset-password/:token" component={ResetPassword} />
          <Route exact path="/callback-stripe" component={CallbackStripe} />
          <Route
            exact
            path="/register/notifications"
            component={RegisterNotifications}
          />
          <Route
            path="/register/password"
            exact
            render={(props) => <RegisterPassword {...props} isRegister />}
          />
          <Route
            path="/register/new-password"
            exact
            render={(props) => <NewAccount {...props} isRegister />}
          />
          <Route
            path="/register/account-details"
            exact
            render={(props) => <AccountDetails {...props} isRegister />}
          />
          <Route
            exact
            path="/register/participant-details/:id"
            render={(props) => <ParticipantDetails {...props} isRegister />}
          />
          <Route
            path="/register/:id"
            exact
            render={(props) => <NewAccount {...props} isRegister />}
          />
          <Route
            exact
            path="/register"
            render={(props) => <Email {...props} isRegister />}
          />
          <Route exact path="/checkout" component={CompleteRegistration} />
          <Route path="/cart" component={Cart} />
          <Route
            exact
            path="/checkout/complete-registration"
            component={CompleteRegistration}
          />
          <Route
            exact
            path="/checkout/success"
            component={RegistrationSuccess}
          />
          <Route
            exact
            path="/checkout/information-request"
            component={InformationRequest}
          />
          <Route path="/account" component={Account} />
          <Route path="/admin" component={Admin} />
          <Route path="/super-admin" component={SuperAdmin} />
          <Route path="/terms-of-service" component={TermsOfService} />
          <Route path="/privacy-policy" component={PrivacyPolicy} />
          <Route path="/store" component={Store} />
          <Route path="/explore" component={Explore} />
          <Route path="/search" component={Search} />
          <Route path="/meets/:id" component={MeetSignup} />
          <Route path="/volunteers/:id" component={MeetSignup} />
          <Route component={NoMatch} />
        </Switch>
      </React.Suspense>
    </Main>
  );
}
