import moment from "moment-timezone";
import OfferingModel from "src/models/OfferingModel";
import { ClassTypes, ParticipantTypes } from "src/utils/constants";
import {
  AgeType,
  CapacityStatus,
  ChargeAmount,
  CheckInAdjustment,
  DepartmentPayload,
  EventType,
  Fee,
  Frequency,
  GroupRegistrationStatus,
  IntegrationType,
  OfferingStatus,
  PriceAdjustment,
  RateType,
  TimeType,
  classItem,
  communications,
  discounts,
  studentInfoRequests,
  tags,
} from "types/code-generator";
import AddonModel from "./AddonModel";
import AgreementModel from "./AgreementModel";
import AttendanceModel from "./AttendanceModel";
import BillingCategoryModel from "./BillingCategoryModel";
import { ChargeModel } from "./ChargeModel";
import ClassTimeModel from "./ClassTimeModel";
import CouponModel from "./CouponModel";
import { CreditModel } from "./CreditModel";
import CustomerRequestModel from "./CustomerRequestModel";
import EvaluationModel from "./EvaluationModel";
import GroupRegistrationModel from "./GroupRegistrationModel";
import LocationModel from "./LocationModel";
import MembershipOfferingModel from "./MembershipOfferingModel";
import OrganizationModel from "./OrganizationModel";
import ProgramModel from "./ProgramModel";
import UserModel from "./UserModel";
import { ZoneModel } from "./ZoneModel";

export const isClass = (item): item is ClassModel => item instanceof ClassModel;

class ClassModel {
  additionalFeeAmount: number = null;
  additionalFeeBillingCategory: BillingCategoryModel = null;
  additionalFeeTitle: string = null;
  addons: AddonModel[] = [];
  agreements: AgreementModel[] = [];
  // allowAdditionalStudents: boolean = false;
  allowCustomerCancellation: boolean = true;
  allowCustomerMove: boolean = true;
  allowNonMembers: boolean = true;
  applyAnnualFee: boolean = true;
  attendedAfterCheckIn: boolean = true;
  attendedOnCheckIn: boolean = false;
  attendances: AttendanceModel[] = [];
  autoEnroll: boolean = false;
  capacity: CapacityStatus;
  charges: ChargeModel[] = [];
  chargeOnCheckIn: boolean = false;
  chargeAmounts: ChargeAmount[] = [];
  // chargeType: ChargeType = ChargeType.Participant;
  checkIn: boolean = false;
  classTimes: ClassTimeModel[] = [];
  collectTaxes: boolean = false;
  communications: communications["communications"] = [];
  coupons: CouponModel[] = [];
  createdAt: Date = null;
  credits: CreditModel[] = [];
  customerCheckIn: boolean = true;
  customerInfoRequests: string[] = [];
  department: DepartmentPayload;
  description: string = null;
  discounts: discounts["discounts"] = [];
  displayOpenSpots: boolean = true;
  displayDaysTimes: boolean = true;
  displayMembershipPricing: boolean = true;
  displayNoteToParticipants: boolean = true;
  earlyCheckIn: number = null;
  earlyCheckInType: TimeType;
  earlyCheckInClose: number = null;
  earlyCheckInCloseType: TimeType;
  endDate: Date = null;
  entryLevel: boolean = true;
  evaluationReminderFrequency: number = null;
  evaluations: EvaluationModel[] = [];
  excludedDates: Date[] = [];
  fees: Fee[] = [];
  freePresentation: string;
  frequency: Frequency;
  fromCustomerRequests: CustomerRequestModel[] = [];
  hideDefaultStaff: boolean = true;
  id: string = null;
  instructors: UserModel[] = [];
  integration: IntegrationType;
  location: LocationModel;
  maintainWaitlist: boolean = true;
  maxAge: number = null;
  maxAgeType: AgeType = AgeType.Year;
  maxCheckIns: number = null;
  maxEnrollment: number = null;
  meetParticipation: boolean = false;
  membershipOfferings: MembershipOfferingModel[] = [];
  membershipPrices: PriceAdjustment[] = [];
  membershipCheckIns: CheckInAdjustment[] = [];
  membershipCheckInsClose: CheckInAdjustment[] = [];
  membershipCheckInPrices: PriceAdjustment[] = [];
  minAge: number = null;
  minAgeType: AgeType = AgeType.Year;
  multiClassDiscount: boolean = true;
  multiStudentDiscount: boolean = true;
  noClassTimes: boolean = false;
  organization: OrganizationModel = null;
  participantType: string = ParticipantTypes.Participant;
  paymentFrequency: Frequency = Frequency.Monthly;
  paymentRate: RateType = RateType.Flat;
  paymentReminderDays: number;
  price: number = null;
  pricePerCheckIn: number = null;
  priceAdjustments: PriceAdjustment[] = [];
  priceForAdditionalStudents: number = null;
  // private: boolean = false;
  program: ProgramModel = null;
  prorateExcludedDates: boolean = true;
  prorateInProgressClasses: boolean = true;
  prorateStartEnd: boolean = true;
  publishDate: Date = null;
  publishOnWebsite: boolean = true;
  registrationClose: Date = null;
  registrationOpen: Date = null;
  registrationAdjustments: classItem["class"]["registrationAdjustments"] = [];
  registrations: GroupRegistrationModel[] = [];
  requiresUSASwimming: boolean = false;
  requiresRegistration: boolean = true;
  sendPaymentReminders: boolean = false;
  showEvaluationReminders: boolean = false;
  startDate: Date = null;
  status: OfferingStatus = OfferingStatus.InProgress;
  studentInfoRequests: studentInfoRequests["studentInfoRequests"] = [];
  tags: tags["tags"] = [];
  templates: OfferingModel[] = [];
  title: string = null;
  type: string = ClassTypes.Class;
  toCustomerRequests: CustomerRequestModel[] = [];
  unpublishDate: Date = null;
  viewOthers: boolean = true;
  waitlistCapacity: number = null;
  zone: ZoneModel;

  constructor(data: ClassModel | any) {
    Object.assign(this, data);

    if (data) {
      if (!data.type) {
        this.type = ClassTypes.Class;
      }
      if (!data.participantType) {
        this.participantType = ParticipantTypes.Participant;
      }
    }
  }

  getRoster(date = new Date()) {
    if (!this.registrations) {
      return [];
    }

    const registrations = this.registrations.filter(
      (r) => r.student || r.roster?.length > 0 || r.studentId
    );
    const timezone = this.organization?.timezone || moment.tz.guess();
    const today = moment().tz(timezone);
    const currentDate = moment(date).tz(timezone);
    // const endDate = moment(this.endDate);

    const filterByStudent = (r) => {
      if (!r.student && !r.studentId) {
        return false;
      }

      const finalDate = r.finalDate && moment(r.finalDate).tz(timezone);
      let onRoster =
        r.status === GroupRegistrationStatus.Approved ||
        r.status === GroupRegistrationStatus.Pending;

      if (r.status === GroupRegistrationStatus.Approved && r.dateApproved) {
        onRoster = moment(r.dateApproved)
          .tz(timezone)
          .isSameOrBefore(currentDate);
      }

      if (r.status === GroupRegistrationStatus.Canceled && r.finalDate) {
        if (
          finalDate &&
          "isAfter" in finalDate &&
          finalDate?.isAfter(currentDate)
        ) {
          onRoster = true;
        }
      }

      if (
        r.status === GroupRegistrationStatus.Completed &&
        currentDate &&
        currentDate?.isBefore(today) &&
        finalDate?.isAfter(currentDate)
      ) {
        const wasApproved = r.events?.find(
          (e) => e.type === EventType.Approved
        );
        const wasWaitlisted =
          r.events?.find((e) => e.type === EventType.Waitlisted) &&
          !r.events?.find((e) => e.type === EventType.Approved);
        const wasCanceledEvent = r.events?.find(
          (e) =>
            e.type === EventType.Canceled || e.type === EventType.CancelApproved
        );
        const wasCanceled =
          wasCanceledEvent &&
          moment(r.finalDate).tz(timezone).isBefore(currentDate);
        const wasMoved =
          r.events?.find((e) => e.type === EventType.MovedFrom) &&
          moment(r.dateApproved).tz(timezone).isBefore(currentDate);

        // if (r.student?.id === "ckilwjqk5sxdx0976sfyc22ra") {
        // console.log("wasApproved", wasApproved);
        // console.log("wasCanceled", wasCanceled);
        // console.log("wasMoved", wasMoved);
        // console.log("wasWaitlisted", wasWaitlisted);
        // console.log(
        // "finalDate",
        // moment(r.finalDate).tz(this.organization?.timezone).format()
        // );
        // }

        if ((wasApproved || wasMoved) && !wasWaitlisted && !wasCanceled) {
          onRoster = true;
        }
        // One-day registration that has passed?
        // onRoster = true;
        // const completedEvent =
        //   r.events && r.events.find(e => e.type === EventType.Completed);
        // if (completedEvent) {
        //   const completedDate = moment(completedEvent.createdAt);
        //   if (completedDate.isBefore(endDate)) {
        //     onRoster = true;
        //   }
        // }
      }

      return onRoster;
    };

    const filterByRoster = (r) => {
      if (!r.roster || !r.roster.length) {
        return false;
      }
      let onRoster =
        r.status === GroupRegistrationStatus.Approved ||
        r.status === GroupRegistrationStatus.Pending;
      if (
        r.status === GroupRegistrationStatus.Canceled &&
        r.finalDate &&
        r.student
      ) {
        const finalDate = moment(r.finalDate);

        if (finalDate && finalDate.isAfter && finalDate.isAfter(currentDate)) {
          onRoster = true;
        }
      }

      return onRoster;
    };

    const students = registrations.reduce((acc, r) => {
      if (filterByStudent(r)) {
        acc[r.student?.id || r.studentId] = r.student || { id: r.studentId };
      }
      if (filterByRoster(r)) {
        r.roster.forEach((student) => {
          acc[student.id] = student;
        });
      }
      return acc;
    }, {});

    return Object.values(students) as UserModel[];
  }

  getClosedRegistrations(date = new Date()) {
    const registrations = this.registrations || [];

    return registrations.filter(
      (r) =>
        r.status === GroupRegistrationStatus.Completed ||
        r.status === GroupRegistrationStatus.Canceled ||
        r.status === GroupRegistrationStatus.Moved
    );
  }

  getWaitlist() {
    const registrations = this.registrations || [];

    const students = registrations.reduce((acc, r) => {
      if (
        (r.student || r.roster || r.studentId) &&
        r.status === GroupRegistrationStatus.Waitlist
      ) {
        if (r.student) {
          acc[r.student.id] = r.student;
        }

        if (r.studentId) {
          acc[r.studentId] = { id: r.studentId };
        }

        if (r.roster) {
          r.roster.forEach((student) => {
            acc[student.id] = student;
          });
        }
      }
      return acc;
    }, {});

    return Object.values(students) as UserModel[];
  }

  getPending() {
    const registrations = this.registrations || [];

    const students = registrations.reduce((acc, r) => {
      if (
        (r.student || r.roster) &&
        r.status === GroupRegistrationStatus.Pending
      ) {
        if (r.student) {
          acc[r.student.id] = r.student;
        }
        if (r.roster) {
          r.roster.forEach((student) => {
            acc[student.id] = student;
          });
        }
      }
      return acc;
    }, {});

    return Object.values(students) as UserModel[];
  }

  get active() {
    return (
      this.status === OfferingStatus.InProgress ||
      this.status === OfferingStatus.Upcoming
    );
  }

  get classTitle() {
    if (this.title) {
      return this.title;
    } else if (this.program) {
      return this.program.title;
    }
    return "";
  }

  get classTimeDays() {
    return this.classTimes
      .reduce((acc, ct) => {
        if (ct.day && !acc.includes(ct.day)) {
          acc.push(ct.day);
        }
        return acc;
      }, [])
      .sort((a, b) => {
        return moment(a, "dddd").valueOf() - moment(b, "dddd").valueOf();
      });
  }

  get numberOfDays() {
    let numberOfDays = 0;

    if (this.startDate && this.endDate) {
      numberOfDays =
        moment(this.endDate).diff(moment(this.startDate), "days") + 1;
    } else if (
      this.startDate &&
      !this.endDate &&
      this.paymentFrequency === Frequency.OneTime
    ) {
      numberOfDays = 1;
    } else if (
      this.startDate &&
      !this.endDate &&
      this.paymentFrequency === Frequency.Monthly
    ) {
      numberOfDays = 99;
    }

    return numberOfDays;
  }

  get available() {
    const available = Math.max(0, this.maxEnrollment - this.getRoster().length);
    return available;
  }

  // get semiPrivate() {
  //   return this.private && this.allowAdditionalStudents === true;
  // }

  // get privatePrivate() {
  //   return this.private && this.allowAdditionalStudents === false;
  // }
}

export default ClassModel;
