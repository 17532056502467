import { gql } from "graphql-tag";
import { FeePayload } from "src/api/Fee";
import { OnboardPayload } from "src/api/Onboard";
import {
  bulkOrganization,
  bulkOrganizationVariables,
  createOrganization,
  createOrganizationVariables,
  currentOrganization,
  currentOrganizationVariables,
  deleteOrganization,
  deleteOrganizationVariables,
  editBulkOrganization,
  editBulkOrganizationVariables,
  financialReport,
  financialReportVariables,
  fullOrganization,
  fullOrganizationVariables,
  importData,
  importDataVariables,
  organization,
  organizationVariables,
  organizationsList,
  organizationsListVariables,
  payouts,
  payoutsVariables,
  revenueOverTime,
  revenueOverTimeVariables,
  unreadItems,
  unreadItemsVariables,
  updateOrganization,
  updateOrganizationVariables,
} from "types/code-generator";
import { TypedDocumentNode } from "types/graphql";
import { DepartmentPayload } from "./Department";

const OrganizationPayload = gql`
  fragment OrganizationPayload on Organization {
    active
    addons {
      id
    }
    address1
    address2
    addDepartmentsDuringOnboarding
    annualPrice
    betaAccess
    chargeStartDate
    city
    color
    contactName
    createdAt
    departments {
      ...DepartmentPayload
    }
    description
    email
    events {
      createdAt
      type
      value
    }
    facebook
    fees {
      ...FeePayload
    }
    gracePeriod
    gracePeriodType
    id
    imports {
      id
      createdAt
      name
      messages
      type
      url
    }
    instagram
    integrations
    lastFailedPayment
    logo {
      id
      url
      name
    }
    meetGenderTerminology
    meetTeamFullName
    meetTeamShortName
    meetTeamAbbreviation
    meetLSC
    monthlyPrice
    nextAnnualInvoice
    nextMonthlyInvoice
    note
    now
    owner {
      id
      balance
      firstName
      lastName
      level
      stripeCustomerId
      stripeToken
    }
    paymentDeadline
    phone
    requiredFields
    score
    solo
    state
    status
    statementDescriptor
    stripe
    stripeAlert
    stripeAccountId
    stripeExpressAccountId
    stripeExpressAccounts {
      id
      stripeExpressAccountId
      default
    }
    stripeFee
    stripeExpressFee
    stripeTransactionFee
    subdomain
    terminology {
      private
      evaluation
      administrator
      staff
      customer
    }
    tags {
      id
      title
    }
    timezone
    title
    twitter
    updatedAt
    usaSwimmingClubId
    usaSwimmingImport
    usaSwimmingClubName
    zip
  }
  ${FeePayload}
  ${DepartmentPayload}
`;

const FullOrganizationPayload = gql`
  fragment FullOrganizationPayload on Organization {
    ...OrganizationPayload
    activeAt
    accountManager {
      id
      firstName
      lastName
    }
    addDepartmentsDuringOnboarding
    agreements {
      id
    }
    annualPrice
    chargeStartDate
    chargeAnnualStartDate
    classes {
      id
      status
    }
    commissionSettings {
      id
      amount
      user {
        id
        firstName
        lastName
      }
      startDate
      endDate
    }
    compare {
      date
      total {
        amount
      }
      captyn {
        amount
      }
      captynCharges {
        amount
      }
      commissions {
        userId
        user
        amount
      }
      refunds {
        amount
        count
      }
    }
    counts {
      accounts
      accountsParticipants
      accountsAdmin
      accountsStaff
      averageInvoice
      registrations
      updated
      invoices
    }
    createdAt
    current {
      date
      total {
        amount
      }
      captyn {
        amount
      }
      captynCharges {
        amount
      }
      commissions {
        userId
        user
        amount
      }
      refunds {
        amount
        count
      }
    }
    disputeFee
    events {
      id
      createdAt
      date
      by {
        firstName
        lastName
      }
      to {
        firstName
        lastName
      }
      type
      value
    }
    gracePeriod
    gracePeriodType
    integrations
    lastContact
    lastContactBy {
      id
      firstName
      lastName
    }
    lastFailedPayment
    locations {
      id
    }
    monthlyPrice
    needs {
      id
      title
    }
    nextMonthlyInvoice
    nextAnnualInvoice
    offerings {
      id
      status
    }
    owner {
      id
      balance
      firstName
      invoices {
        id
        status
        invoiceItems {
          id
          description
          amount
          captyn
        }
        createdAt
        invoiceDate
      }
      lastName
      email
      stripeCustomerId
      stripeToken
    }
    onboards {
      ...OnboardPayload
    }
    paymentDeadline
    pipedriveUrl
    planContent
    priceAdjustments {
      id
      date
      fee
    }
    projectedCaptynRevenue
    projectedFirstPaidRegistration
    projectedOperatingMonths
    projectedRegistrations
    projectedRevenue
    referralName
    salesPerson {
      id
      firstName
      lastName
    }
    signupInformation
    status
    statementDescriptor
    stripe
    tags {
      id
      title
    }
    usaSwimmingClubId
    usaSwimmingImport
    website
    websiteIncluded
  }
  ${OrganizationPayload}
  ${OnboardPayload}
`;

export const UpdateOrganizationMutation = gql`
  mutation updateOrganization(
    $data: OrganizationCreateInput!
    $where: UniqueInput!
  ) {
    updateOrganization(data: $data, where: $where) {
      ...OrganizationPayload
    }
  }
  ${OrganizationPayload}
` as TypedDocumentNode<updateOrganization, updateOrganizationVariables>;

export const DeleteOrganizationMutation = gql`
  mutation deleteOrganization($id: String!) {
    deleteOrganization(where: { id: $id }) {
      id
    }
  }
` as TypedDocumentNode<deleteOrganization, deleteOrganizationVariables>;

export const CreateOrganizationMutation = gql`
  mutation createOrganization($data: OrganizationCreateInput!) {
    createOrganization(data: $data) {
      ...OrganizationPayload
    }
  }
  ${OrganizationPayload}
` as TypedDocumentNode<createOrganization, createOrganizationVariables>;

export const OrganizationsQuery = gql`
  query organizationsList {
    organizations(
      where: { subdomain: { notIn: ["app", "app-dev"] } }
      orderBy: { title: asc }
    ) {
      accountManager {
        id
      }
      betaAccess
      city
      color
      contactName
      createdAt
      description
      email
      id
      logo {
        id
        url
      }
      meetLSC
      meetTeamAbbreviation
      note
      salesPerson {
        id
      }
      state
      status
      stripeAccountId
      subdomain
      title
    }
  }
` as TypedDocumentNode<organizationsList, organizationsListVariables>;

export const OrganizationQuery = gql`
  query organization($id: String!) {
    organization(where: { id: $id }) {
      ...OrganizationPayload
    }
  }
  ${OrganizationPayload}
` as TypedDocumentNode<organization, organizationVariables>;

export const FullOrganizationQuery = gql`
  query fullOrganization($id: String!, $filter: String) {
    organization(where: { id: $id }, filter: $filter) {
      ...FullOrganizationPayload
    }
  }
  ${FullOrganizationPayload}
` as TypedDocumentNode<fullOrganization, fullOrganizationVariables>;

export const RevenueOverTimeQuery = gql`
  query revenueOverTime($organization: ID!) {
    revenueOverTime(organization: $organization)
  }
` as TypedDocumentNode<revenueOverTime, revenueOverTimeVariables>;

export const FinancialReportQuery = gql`
  query financialReport($where: FinancialReportInput!) {
    financialReport(where: $where) {
      csv
      invoiceItems
      refundsIssued
      totalCharged
      totalTransactions
    }
  }
` as TypedDocumentNode<financialReport, financialReportVariables>;

export const PayoutsQuery = gql`
  query payouts($where: FinancialReportInput!) {
    payouts(where: $where)
  }
` as TypedDocumentNode<payouts, payoutsVariables>;

export const CurrentOrganizationQuery = gql`
  query currentOrganization($subdomain: String!) {
    organization(where: { subdomain: $subdomain, status: { not: Suspended } }) {
      ...OrganizationPayload
    }
  }
  ${OrganizationPayload}
` as TypedDocumentNode<currentOrganization, currentOrganizationVariables>;

export const UnreadItemsQuery = gql`
  query unreadItems($organization: ID!, $departments: [ID]) {
    unreadItems(organization: $organization, departments: $departments) {
      allClasses
      allOfferings
      customerServiceItems
      enrolledParticipants
      inProgressClasses
      inProgressMeets
      inProgressPrivateSeries
      inProgressMembershipOfferings
      allMembershipOfferings
      now
      passOfferings
      pendingGroupRegistrations
      pendingInvoiceItems
      pendingMemberships
      pendingPrivateSeries
      postedPrivateSeries
      upcomingClasses
      upcomingMeets
      waitlistAvailableClasses
    }
  }
` as TypedDocumentNode<unreadItems, unreadItemsVariables>;

export const UnreadItemsUpdatedSubscription = gql`
  subscription unreadItemsUpdated($organization: ID!, $departments: [ID]) {
    unreadItemsUpdated(organization: $organization, departments: $departments) {
      allClasses
      allOfferings
      customerServiceItems
      enrolledParticipants
      inProgressClasses
      inProgressMeets
      inProgressPrivateSeries
      membershipOfferings
      now
      passOfferings
      pendingGroupRegistrations
      pendingInvoiceItems
      pendingMemberships
      pendingPrivateSeries
      postedPrivateSeries
      upcomingClasses
      upcomingMeets
      waitlistAvailableClasses
    }
  }
`;

export const ImportDataMutation = gql`
  mutation importData($data: ImportDataInput!) {
    importData(data: $data)
  }
` as TypedDocumentNode<importData, importDataVariables>;

export const BulkOrganizationQuery = gql`
  query bulkOrganization($filter: String) {
    bulkOrganization(filter: $filter) {
      needs
      tags
      total
    }
  }
` as TypedDocumentNode<bulkOrganization, bulkOrganizationVariables>;

export const EditBulkOrganizationMutation = gql`
  mutation editBulkOrganization(
    $data: BulkOrganizationCreateInput!
    $filter: String
  ) {
    editBulkOrganization(data: $data, filter: $filter) {
      count
    }
  }
` as TypedDocumentNode<editBulkOrganization, editBulkOrganizationVariables>;
